import { FC, HTMLAttributes } from 'react';

type PlusIconProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const Plus: FC<PlusIconProps> = ({
  size = 15,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10 4.50012V16.1668"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M4.1665 10.3335H15.8332"
        stroke={color}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Plus;
