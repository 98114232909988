type EndingCheckProps = React.SVGProps<SVGSVGElement> & {
  size?: number;
  color?: string;
};

const EndingCheck: React.FC<EndingCheckProps> = ({
  size = 24,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 57 57"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g opacity="0.2" filter="url(#filter0_di_1782_23066)">
        <path
          d="M28.8593 53.6696C36.05 53.6696 42.56 50.7549 47.2724 46.0426C51.9847 41.3303 54.8993 34.8203 54.8993 27.6296C54.8993 20.4389 51.9847 13.9289 47.2724 9.21653C42.56 4.50423 36.05 1.5896 28.8593 1.5896C21.6687 1.5896 15.1587 4.50423 10.4463 9.21653C5.73397 13.9289 2.81934 20.4389 2.81934 27.6296C2.81934 34.8203 5.73397 41.3303 10.4463 46.0426C15.1587 50.7549 21.6687 53.6696 28.8593 53.6696Z"
          fill={color}
        />
      </g>
      <path
        d="M18.2168 28.8697L25.6568 36.3097L40.5368 21.4297"
        stroke={color}
        stroke-width="4.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <defs>
        <filter
          id="filter0_di_1782_23066"
          x="0.819336"
          y="0.5896"
          width="56.0801"
          height="56.0801"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="1" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_1782_23066"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_1782_23066"
            result="shape"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset />
          <feGaussianBlur stdDeviation="5" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.02 0"
          />
          <feBlend
            mode="normal"
            in2="shape"
            result="effect2_innerShadow_1782_23066"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default EndingCheck;
