import { FC, SVGProps } from 'react';

type WalletProps = SVGProps<SVGSVGElement> & {
  color?: string;
  size?: number;
};

const Wallet: FC<WalletProps> = ({
  size = 21,
  color = 'currentColor',
  ...props
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={(size * 18) / 21}
    fill="none"
    {...props}
  >
    <path
      fill={color}
      d="M18.353 13.531h-4.275c-.78 0-1.412-.618-1.412-1.381v-2.8c0-.764.632-1.382 1.412-1.382h2.88V5.849a.711.711 0 0 0-.214-.508.743.743 0 0 0-.52-.21H5.492a.69.69 0 0 1-.697-.682.69.69 0 0 1 .697-.681h10V.95a.69.69 0 0 0-.698-.682H5.49C2.734.268.5 2.454.5 5.15v10.5c0 .552.224 1.082.623 1.472.4.39.94.61 1.505.61h13.596c.565 0 1.106-.22 1.506-.61.398-.39.623-.92.623-1.472V13.53Z"
    />
    <path
      fill="#fff"
      fillOpacity={0.1}
      fillRule="evenodd"
      d="M12.666 9.35c0-.764.633-1.382 1.413-1.382h5.009c.78 0 1.412.618 1.412 1.381v2.8c0 .764-.632 1.382-1.412 1.382h-5.01c-.78 0-1.412-.619-1.412-1.382v-2.8Z"
      clipRule="evenodd"
    />
    <rect
      width={5.054}
      height={2.836}
      x={14.056}
      y={9.331}
      fill={color}
      rx={0.1}
    />
  </svg>
);
export default Wallet;
