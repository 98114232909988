import { FC } from 'react';
import { cn } from '~/lib/utils';

type SpinnerProps = {
  className?: string;
  size?: number;
  thickness?: number;
};

const Spinner: FC<SpinnerProps> = ({
  className,
  size = 40,
  thickness = (size * 10) / 100,
}) => {
  return (
    <div
      className={cn(
        'aspect-square animate-spin rounded-full border-solid border-neutral-300 border-b-neutral-500 ease-in-out',
        className,
      )}
      style={{ width: size, borderWidth: thickness }}
    ></div>
  );
};

export default Spinner;
