import { FC, SVGProps } from 'react';

type LockProps = SVGProps<SVGSVGElement> & {
  className?: string;
  size?: number;
  color?: string;
};
const Lock: FC<LockProps> = ({
  size = 25,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={(size * 24) / 25}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.7528 19.0001V12.2104C19.7528 11.571 19.161 11.0958 18.5367 11.234L15.9213 11.8129C13.9285 12.2539 11.8634 12.2539 9.87059 11.8129L7.25518 11.234C6.63088 11.0958 6.03907 11.571 6.03909 12.2104L6.03922 19.0001C6.03923 19.5524 6.48694 20.0001 7.03922 20.0001H18.7528C19.3051 20.0001 19.7528 19.5524 19.7528 19.0001Z"
        fill={color}
        stroke={color}
        strokeWidth="2"
        strokeLinejoin="round"
      />
      <path
        d="M8.6875 11.5158V8.17542C8.6875 5.8694 10.5704 4 12.893 4C15.2157 4 17.0986 5.8694 17.0986 8.17542V11.5158"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="12.8953" cy="15.959" r="1.45" fill="white" />
    </svg>
  );
};

export default Lock;
