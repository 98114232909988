'use client';

import {
  AvatarFallbackProps,
  AvatarImageProps,
  AvatarProps as AvatarRootProps,
} from '@radix-ui/react-avatar';
import { FC } from 'react';
import {
  AvatarFallback,
  AvatarImage,
  Avatar as AvatarRoot,
} from '~/components/ui/avatar';

type AvatarProps = AvatarRootProps & {
  image?: AvatarImageProps;
  fallback?: AvatarFallbackProps;
};

const Avatar: FC<AvatarProps> = ({ image, fallback, ...props }) => {
  return (
    <AvatarRoot {...props}>
      <AvatarImage {...image} />
      <AvatarFallback {...fallback} />
    </AvatarRoot>
  );
};

export default Avatar;
