type SvgLoaderProps = React.SVGProps<SVGSVGElement> & {
  size?: number;
  color?: string;
};

const SvgLoader: React.FC<SvgLoaderProps> = ({
  size = 25,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.5004 3.17438C14.401 3.17447 16.2528 3.77624 17.7904 4.89344C19.3279 6.01064 20.4723 7.58592 21.0596 9.3935C21.6469 11.2011 21.6468 13.1482 21.0594 14.9557C20.4721 16.7633 19.3276 18.3385 17.79 19.4556C16.2523 20.5727 14.4005 21.1744 12.4999 21.1744C10.5993 21.1744 8.74755 20.5727 7.20994 19.4555C5.67233 18.3384 4.52785 16.7632 3.94052 14.9556C3.35319 13.1481 3.35316 11.201 3.94044 9.39338"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SvgLoader;
