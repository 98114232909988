import { FC, HTMLAttributes } from 'react';

type LoaderProps = HTMLAttributes<SVGElement> & {
  size?: number;
  color?: string;
};

const Loader: FC<LoaderProps> = ({
  size = 20,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <line x1="12" x2="12" y1="2" y2="6"></line>
      <line x1="12" x2="12" y1="18" y2="22" strokeOpacity={0.1}></line>
      <line x1="4.93" x2="7.76" y1="4.93" y2="7.76"></line>
      <line
        x1="16.24"
        x2="19.07"
        y1="16.24"
        y2="19.07"
        strokeOpacity={0.3}
      ></line>
      <line x1="2" x2="6" y1="12" y2="12"></line>
      <line x1="18" x2="22" y1="12" y2="12" strokeOpacity={0.5}></line>
      <line x1="4.93" x2="7.76" y1="19.07" y2="16.24"></line>
      <line
        x1="16.24"
        x2="19.07"
        y1="7.76"
        y2="4.93"
        strokeOpacity={0.7}
      ></line>
    </svg>
  );
};

export default Loader;
