import { FC } from 'react';

type DividerProps = {
  children?: React.ReactNode;
};

const Divider: FC<DividerProps> = ({ children }) => {
  return (
    <div className="my-6 flex h-[1.5px] w-full items-center justify-center bg-black/5">
      {children && (
        <div className="bg-white px-3 text-sm font-medium text-black/30">
          {children}
        </div>
      )}
    </div>
  );
};

export default Divider;
