type ClosedFormIconProps = React.SVGProps<SVGSVGElement> & {
  size?: number;
  color?: string;
};

const ClosedFormIcon: React.FC<ClosedFormIconProps> = ({
  size = 63,
  color = 'black',
  ...props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 63 63"
      fill="none"
      {...props}
    >
      <path
        d="M57.8979 47.7253L35.8706 9.17742C35.3903 8.32993 34.6938 7.62501 33.8521 7.13458C33.0105 6.64414 32.0538 6.38574 31.0796 6.38574C30.1055 6.38574 29.1488 6.64414 28.3071 7.13458C27.4655 7.62501 26.769 8.32993 26.2887 9.17742L4.26134 47.7253C3.77587 48.566 3.5213 49.5202 3.52345 50.4911C3.5256 51.462 3.78439 52.415 4.27358 53.2536C4.76278 54.0923 5.46499 54.7866 6.30905 55.2664C7.1531 55.7462 8.10899 55.9943 9.07982 55.9855H53.1345C54.1007 55.9845 55.0496 55.7294 55.8859 55.2456C56.7223 54.7619 57.4167 54.0666 57.8993 53.2297C58.382 52.3927 58.6359 51.4434 58.6357 50.4773C58.6354 49.5111 58.381 48.562 57.8979 47.7253Z"
        fill={color}
        fill-opacity="0.15"
      />
      <path
        d="M31.0781 22.7646L31.0781 36.2529"
        stroke={color}
        stroke-opacity="0.3"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M31.0781 45.416H31.104"
        stroke={color}
        stroke-opacity="0.3"
        stroke-width="4"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default ClosedFormIcon;
