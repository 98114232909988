type FormoProps = React.SVGProps<SVGSVGElement> & {
  size?: number;
  color?: string;
};

const Formo: React.FC<FormoProps> = ({
  size = 24,
  color = 'currentColor',
  ...props
}) => {
  return (
    <svg
      width={(size * 113) / 19}
      height={size}
      viewBox="0 0 113 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M102.234 18.2722C96.2134 18.2722 92.167 16.1009 92.167 10.7715C92.167 5.41735 96.2134 3.24609 102.234 3.24609C108.229 3.24609 112.3 5.41735 112.3 10.7715C112.3 16.1009 108.229 18.2722 102.234 18.2722ZM102.234 14.7439C106.132 14.7439 108.057 13.7816 108.057 10.7715C108.057 7.73665 106.132 6.77438 102.234 6.77438C98.3107 6.77438 96.3861 7.73665 96.3861 10.7715C96.3861 13.7816 98.3107 14.7439 102.234 14.7439Z"
        fill={color}
      />
      <path
        d="M84.1566 3.24609C87.4135 3.24609 90.3743 4.6278 90.4236 9.88323L90.3743 18.0254H86.1551V11.5857C86.1551 8.50152 85.637 6.89775 82.6269 6.89775C80.4063 6.89775 78.235 7.95871 76.952 9.11835C76.9767 9.36509 76.9767 9.61182 76.9767 9.88323L76.9273 18.0254H72.7082V11.5857C72.7082 8.50152 72.19 6.89775 69.1799 6.89775C66.7372 6.89775 64.3686 8.15609 63.1596 9.46378V18.0254H58.9404V3.49283H63.1596V5.86147C64.7387 4.60313 67.1073 3.24609 70.7096 3.24609C72.9056 3.24609 74.9781 3.8876 76.0884 5.91082C77.9636 4.50443 80.5543 3.24609 84.1566 3.24609Z"
        fill={color}
      />
      <path
        d="M53.0396 3.24609C54.7668 3.24609 55.9758 3.49283 56.9627 3.73956V7.26785C55.581 6.92243 54.4707 6.77438 53.237 6.77438C50.8684 6.77438 48.1543 7.3172 45.5883 9.31574V18.0254H41.3691V3.49283H45.5883V5.96016C47.7595 4.01097 50.3256 3.24609 53.0396 3.24609Z"
        fill={color}
      />
      <path
        d="M29.4495 18.2722C23.4292 18.2722 19.3828 16.1009 19.3828 10.7715C19.3828 5.41735 23.4292 3.24609 29.4495 3.24609C35.4452 3.24609 39.5163 5.41735 39.5163 10.7715C39.5163 16.1009 35.4452 18.2722 29.4495 18.2722ZM29.4495 14.7439C33.3479 14.7439 35.2725 13.7816 35.2725 10.7715C35.2725 7.73665 33.3479 6.77438 29.4495 6.77438C25.5265 6.77438 23.602 7.73665 23.602 10.7715C23.602 13.7816 25.5265 14.7439 29.4495 14.7439Z"
        fill={color}
      />
      <path
        d="M18.7505 4.47958H4.93339V8.87144H17.2701V12.6218H4.93339V18.0253H0.492188V0.753906H18.7505V4.47958Z"
        fill={color}
      />
    </svg>
  );
};

export default Formo;
