import { FC, HTMLAttributes } from 'react';
import { cn } from '~/lib/utils';

type TagProps = HTMLAttributes<HTMLDivElement>;

const Tag: FC<TagProps> = ({ className, children, ...props }) => {
  return (
    <div
      className={cn(
        'w-max min-w-14 rounded-[10px] border border-solid px-2.5 py-[3px] text-center text-sm font-medium leading-normal',
        className,
      )}
      {...props}
    >
      {children}
    </div>
  );
};

export default Tag;
